<script setup lang="ts">
import { Link, useForm } from "@inertiajs/vue3";
import PrimaryButton from "@/Components/Form/PrimaryButton.vue";
import InputError from "@/Components/Form/InputError.vue";
import InputField from "@/Components/Form/InputField.vue";
import ArrowRight from "@/Components/Icons/ArrowRight.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";

const form = useForm({
    first_name: "",
});
</script>

<template>
    <BaseLayout class="flex flex-col items-center justify-center" :animationDuration="300">
        <div class="w-60 pb-12 pt-6 md:max-w-xs">
            <div class="text-md mb-12 font-bold uppercase text-gray-900">Willkommen!</div>

            <form @submit.prevent="form.post(route('welcome.store'))">
                <label for="first_name" class="mb-6 block font-serif text-3xl">Wie heißt du?</label>

                <div class="mb-4">
                    <InputField
                        id="first_name"
                        type="text"
                        v-model="form.first_name"
                        placeholder="Vorname"
                        :error="!!form.errors.first_name"
                    />
                    <InputError :message="form.errors.first_name" />
                </div>

                <PrimaryButton>Start</PrimaryButton>
            </form>

            <div class="mt-6">
                <Link class="group text-sm text-gray-700 hover:text-gray-900" :href="route('login.view')">
                    Du hast bereits ein Benutzerkonto?
                    <ArrowRight
                        class="inline h-4 w-4 text-gray-600 transition ease-in-out group-hover:translate-x-1 group-hover:text-gray-900"
                    />
                </Link>
            </div>
        </div>
    </BaseLayout>
</template>